import { useQuery } from 'react-query'
import { api } from '#app/utils/api'

export const useAddressAutocompleteQuery = (address: string) =>
	useQuery({
		queryKey: ['search-address', address],
		queryFn: async () => {
			const { data } = await api.searchAddresses(address)
			if ('errors' in data) return []
			if (!data.length || data[0].value === address) return []

			return data
		},
		enabled: !!address,
	})
