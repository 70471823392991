import { CommandGroup, CommandItem } from '#app/components/ui/command'
import { cn } from '#app/utils/misc'
import { useAddressAutocompleteQuery } from '../hooks/use-address-autocomplete-query'

export const AddressAutocomplete = ({
	address,
	onAutocomplete,
	className,
}: {
	address: string
	onAutocomplete: (value: string) => void
	className?: string
}) => {
	const query = useAddressAutocompleteQuery(address)
	if (!query.data?.length) return null

	return (
		<CommandGroup
			className={cn(
				'absolute left-0 top-20 z-50 w-96 rounded border bg-white shadow-sm',
				className,
			)}
		>
			{query.data.map(({ value }) => (
				<CommandItem
					key={value}
					value={value}
					className="cursor-pointer text-sm"
					onSelect={() => onAutocomplete(value)}
				>
					{value}
				</CommandItem>
			))}
		</CommandGroup>
	)
}
